import Error from 'next/error';
import Link from 'next/link';
import { parseCookies } from 'nookies';
import { cookies } from 'utils/constants';

export default function nonAuth(Component, onlyNonLogin = true) {
  const Wrapper = ({ statusCode, ...props }: {
    statusCode: number;
  }) => {
    if (statusCode) {
      return (<>
        <Error statusCode={statusCode} title={statusCode === 403 ? 'Forbidden' : null} />
        <p className="fixed w-full top-1/2 mt-48 text-center">Back to <Link passHref href="/">Homepage</Link></p>
      </>);
    }

    return <Component {...props} />
  }

  Wrapper.getLayout = Component.getLayout || ((page) => page);

  Wrapper.getInitialProps = async (ctx) => {
    const allCookies = parseCookies(ctx);
    let statusCode = 0;
    if (allCookies[cookies.token] && onlyNonLogin) statusCode = 403;
    const canGetInitialProps = (!statusCode && onlyNonLogin) || !onlyNonLogin;

    const getInitialProps = Component.getInitialProps && canGetInitialProps ? await Component.getInitialProps(ctx) : {};

    return {
      getInitialProps,
      statusCode,
      hasLoginToken: Boolean(allCookies[cookies.token]),
    };
  }

  return Wrapper;
}
