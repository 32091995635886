import NavbarLanding from "components/NavbarLanding";
import ScrollToTop from "components/ScrollToTop";
import Footer from "../Footer";
import MainHead from "../main/MainHead";
import { appHostProd } from 'utils/constants';
import config from 'utils/config';

const LandingPageLayout: React.FC<{
  children: React.ReactNode;
  hasLoginToken?: boolean;
  title?: string;
  keywords?: string;
  description?: string;
  titleOg?: string;
  descriptionOg?: string;
  imageUrl?: string;
  hostUrl?: string;
  author?: string;
}> = ({
  title,
  keywords,
  description,
  children,
  hasLoginToken,
}) => {
  const isMainDomain = config.cookiesConfig.domain === appHostProd;

  return (
    <>
      <MainHead
        title={title}
        description={description}
        keywords={keywords}
        robots={isMainDomain ? 'index,follow' : 'noindex,nofollow,noarchive'}
        googleBot={isMainDomain ? 'index,follow' : 'noindex,nofollow,noarchive'}
      />

      <div className="w-full min-h-screen landing">
        <NavbarLanding hasLoginToken={hasLoginToken} />
        {children}
        <Footer />
        <ScrollToTop />
      </div>
    </>
  );
};

export default LandingPageLayout;
