import { useEffect, useState } from 'react';

export default function ScrollToTop() {
  const [scrolled, setScrolled] = useState(false);

  function scrollListener() {
    const isScrolled = window.scrollY > window.innerHeight;
    setScrolled(isScrolled);
  }

  useEffect(() => {
    document.addEventListener('scroll', scrollListener);

    return () => {
      document.removeEventListener('scroll', scrollListener);
    }
  }, []);

  return (
    <span onClick={() => window.scrollTo(0, 0)} className={`text-white cursor-pointer font-bold text-title1 fixed right-16 ipad:right-48 bottom-16 ipad:bottom-48 w-48 h-48 flex items-center justify-center z-30 bg-gradient-to-bl from-primary to-info-light rounded-lg shadow-lg border border-grey-lightest transition-all transform ${scrolled ? 'translate-y-0 visible opacity-100' : 'opacity-0 invisible translate-y-24'}`}>
      <i className="ri-arrow-up-s-line" />
    </span>
  );
}
